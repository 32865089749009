import React from 'react';
import '../styles/Actualites.css';
import ImgActu1 from '../imagesActu/Actu1.jpg';
import ImgActu2 from '../imagesActu/Actu2.jpg';
import ImgActu3 from '../imagesActu/Actu3.jpg';
import ImgActu4 from '../imagesActu/Actu4.jpg';
import Footer from '../components/Footer';
import ImpFormIns from "../components/ImpFormIns";

const Actualites = () => {
    return (
        <div className='Actualites'>
            <div className='Actualites'>
                <h1 className='ActuH1'> 2023</h1>
            </div>    
            <div className='ActuItems'>
                    <div className='ActuH2c'>
                        <h2 className='ActuH2c' > </h2>
                    </div>
                    <div className='ActuLig'>
                           
                        <img className='ImgActuA4' src={ImgActu4} alt="Actualite 01 Octobre 2023" />
                         
                        <div className='TextH2'>
                            <h2 className='ActuH2b'>01 Octobre 2023
                            </h2>
                            <h2 className='ActuH2d'>L'Amicale de la gare de Trôo organise un repas dans les caves de Thoré la Rochette.
                            </h2>
                            <h2 className='ActuH2d'>
TTVL ne pourra malheureusement pas nous mettre de train à disposition comme lors des editions précédentes.
                            </h2>
                            <h2 className='ActuH2d'>
Nous vous proposons de nous retrouver en gare de Trôo pour nous rendre en covoiturage dans les caves de Thoré.
                            </h2>
                            <h2 className='ActuH2d'>
Pour plus d'informations contacter Annette Saillard ou envoyez-nous un mail à garedetroo@gmail.com.
                            </h2>
                            
                            <ImpFormIns />
                        </div>
                        
                    </div>
                    <div className='ActuH2c'>
                        <h2 className='ActuH2c' > </h2>
                        
                    </div>
                    <div className='ActuLig'>
                        <img className='ImgActu' src={ImgActu3} alt="Actualite 22 Juin 2023" />
                        <div className='TextH2'>
                            <h2 className='ActuH2b'>22 Juin 2023
                            </h2>
                            <h2 className='ActuH2d'>L'Amicale a fêté avec ses bénévoles les 25 ans de son existence 
                            autour d'un bon repas à la Guinguette des Iles à Troo.
                            </h2>
                        </div>    
                    </div>
                    <div className='ActuH2c'>
                        <h2 className='ActuH2c' > </h2>
                        
                    </div>
                    <div className='ActuLig'> 
                        <img className='ImgActuA4' src={ImgActu2} alt="Actualite 14 Mai 2023" />
                        <div className='TextH2'>
                            <h2 className='ActuH2b'>14 Mai 2023
                            </h2>
                            <h2 className='ActuH2d'>Voyage de l'amicale au musée de l'Hotêl Dieu à Baugé en Anjou 
                            et au musée des anciens commerces de Doué la Fontaine. 
                            Une très belle journée, avec le soleil, agrémentée d'un repas original aux cathédrales de la Saulaie à Doué la Fontaine.
                            </h2>
                        </div>
                    </div>
                    <div className='ActuH2c'>
                        <h2 className='ActuH2c' > </h2>
                        
                    </div>
                    <div className='ActuLig'> 
                        <img className='ImgActu' src={ImgActu1} alt="Actualite 04 Mars 2023" />
                        <div className='TextH2'>
                            <h2 className='ActuH2b'>04 Mars 2023
                            </h2>
                            <h2 className='ActuH2d'>Assemblée générale de l'Amicale à 14h30 à la salle des fêtes de Trôo.
                            </h2>
                        </div>
                    </div> 

            </div>
            <div className='ActuFooter'>    
                <Footer />
            </div>
        </div>
    );
};

export default Actualites;