import React from 'react';
import '../styles/Horaires.css';



function EntetePageHoraires() {

    return (
        <div className='Horaires'>
        <h2 className='LigneH2t' > - </h2>
        {/* <h2 className='LigneH2' >La gare sera exceptionnellemnt fermée les Mercredis après midi</h2>
        
        <h2 className='LigneH2' >pendant quelques semaines</h2>
        <h2 className='LigneH2' >Rassurez vous les modélistes reviennent bientôt</h2>
        <h2 className='LigneH2t' > - </h2> */}
        <div className='LigPagHo'>
        <h2 className='LigneH2h2' >Le musée de la gare de Trôo est ouvert</h2>
        </div>
        <div className='LigPagHo'>
            <h2 className='LigneH2h' > &rArr; Tous les Mercredis Aprés midi de 14H30 à 17H00 pour ses ateliers de modélisme. Accueil au 2eme étage de la gare.</h2>
        </div>
        <h2 className='LigneH2t' > - </h2>
        {/* <div className='LigPagHo'> */}
            {/* <h2 className='LigneH2r' > La gare de Trôo sera fermée les 25 Décembre 2024 et 01 Janvier 2025</h2> */}
            {/* <h2 className='LigneH2r' > Retour de nos modélistes tous les Mercredis à partir du 08 Janvier 2025</h2> */}
            {/* <h2 className='LigneH2r' > Bonne année à tous</h2> */}
        {/* </div> */}
        
        
        <h2 className='LigneH2t' > - </h2>
        <h2 className='LigneH2h' > &rArr; Pour un autre jour, groupes, nous contacter. Voir onglet Contact</h2>
        <h2 className='LigneH2t' > - </h2>
                    <h2 className='LigneH2t' > - </h2>
        <h2 className='LigneH2hb' >Tarif : 1,5 Euro pour les adultes, gratuit pour les enfants de moins de 10 ans accompagnés</h2>
        <h2 className='LigneH2hb' > </h2>
        <h2 className='LigneH2hb' >Buvette et Boutique à la gare pendant les horaires d'ouverture. Tables de pique-nique disponibles à l'extérieur</h2>
        <h2 className='LigneH2hb' > </h2>
        
        <h2 className='LigneH2t' > - </h2>
        <div className='LigPagHo'>
        {/* <table className='HoTbl2' align='center'>
            <tbody>
                <tr className='HoTblli'>
                    <td className='HoTblli'>
                    <h2 className='HoTbllit2' >Planning prévisionnel. Le Planning est susceptible d'être modifié pensez à le consulter régulierement </h2>
                    </td>
                </tr>
            </tbody> */}
        {/* </table> */}
        </div>
        </div>
    );
};

export default EntetePageHoraires;
