import React from 'react';
import '../../styles/BoutiqueDetail.css';
import MagAgt from '../../images/MagAgt.jpg';
import MagJM from '../../images/MagJM.jpg';
import MagTT from '../../images/MagTT.jpg';
import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const MagnetsDetail = () => {
    
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Magnets</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='BtqDetail ColonBtqDetail'>
                <div className='Mag'>
                <h3 className='H4BtqDetail'>Magnets créés par l'Amicale </h3>
                <img className='ImagBtqMag' src={MagAgt} alt="Magnets Amicale" />
                </div>
                <h3> - </h3>
                <div className='Mag'>
                <h3 className='H4BtqDetail'>par
                <a className='LnkBtq' href="https://trootourisme.jimdofree.com/" target="_blank" rel="noreferrer"> Coeur de Trôo (ex Trôo Tourisme)</a>
                </h3>
                <img className='ImagBtqMag' src={MagTT} alt="Magnets Troo Tourisme" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MagnetsDetail;