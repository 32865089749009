import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import '../styles/Boutique.css';


function ImgAccueilBoutique() {

    const navigate = useNavigate();

    return (
        <div className='Boutique'>

            <h1 className='BoutiqueH1'> Vous trouverez à la Boutique Souvenirs du musée de la Gare de Trôo</h1>
            
            <div >onClick
            <table >
            <tbody>
                <tr>
                    <td><Button className='BtnBtqR' onClick={() => navigate('/Nouveautes')}>Nouveautés</Button> </td>
                
                </tr>
                <tr>
                    <td><Button className='BtnBtq' onClick={() => navigate('/CartesDetail')}>Cartes postales</Button> </td>
                
                </tr>
                <tr>
                    <td><Button className='BtnBtq' onClick={() => navigate('/LivresDetail')}>Livres</Button> </td>
                
                </tr>
                <tr>
                    <td><Button className='BtnBtq' onClick={() => navigate('/MugsDetail')}>Mugs</Button> </td>
                    
                </tr>
                <tr>
                    <td><Button className='BtnBtq' onClick={() => navigate('/SetsDetail')}>Sets de table</Button> </td>
                
                </tr>
                <tr>
                    <td><Button className='BtnBtq' onClick={() => navigate('/TeeshirtsDetail')}>Tee shirts</Button> </td>
                
                </tr>
                <tr>
                    <td><Button className='BtnBtq' onClick={() => navigate('/MagnetsDetail')}>Magnets</Button> </td>
                
                </tr>
                <tr>
                    <td><Button className='BtnBtq' onClick={() => navigate('/DiversDetail')}>Divers</Button> </td>
                
                </tr>
             </tbody>
            </table> 
             
            </div>
                        
        </div>
    );
};

export default ImgAccueilBoutique;
