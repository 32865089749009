import React from 'react';
import '../../styles/Presentation.css';

const Benevoles = () => {
    return (
        <div className='Gare'>
        <div className='LigneTitre'>
            <h1 className="LigneTitre"> Les Bénévoles de l amicale de la gare de Trôo</h1>
        </div>
        
        <div className='GareLigne ColonPres'>
            <h2 className='GareLigne ColonPres GareH2b'> Toujours dans une bonne ambiance, les rôles des bénévoles sont multiples :</h2>
            <h2 className='GareLigne ColonPres GareH2b'> 1 Accueil des passagers du TTVL lors des arrêts du train en gare de Trôo</h2>
            <h2 className='GareLigne ColonPres GareH2b'> 2 Animation des ateliers modélisme</h2>
            <h2 className='GareLigne ColonPres GareH2b'> 3 Entretien de la gare et de ses alentours</h2>
            <h2 className='GareLigne ColonPres GareH2b'> 4 Organisation de manifestations diverses (repas, voyages)</h2>
            <h2 className="GareLigne ColonPres GareH2c"> Rejoignez nous </h2>
            
        </div>
    </div>
    );
};

export default Benevoles;