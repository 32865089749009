import React from 'react';
import '../../styles/BoutiqueDetail.css';
import ImgGareTrooEt21 from '../../images/Detage1.jpg';
import CartesTTVL from '../../images/CartesTTVL.jpg';
import CartesRegion from '../../images/CartesRegion.jpg';
import CartesDucourtioux from '../../images/CartesDucourtioux.jpg';
import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const CartesDetail = () => {
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Nos Cartes postales</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='ColonBtqDetail'>
                <h3 className='H4BtqDetail'>Voici un aperçu des cartes postales que vous trouverez dans notre boutique</h3>
            </div>
            
            
            <div className='ColonBtqDetail'>
                <h3 className='H3BtqDetail'>Cartes du train touristique (TTVL)</h3>
                <div className='Mag'>
                    <img className='ImagBtqDetail' src={CartesTTVL} alt="Cartes TTVL" />
                </div> 
            </div>
        
            <div className='ColonBtqDetail'>
                <h3 className='H3BtqDetail'>Cartes de la région</h3>
                <div className='Mag'>
                    <img className='ImagBtqDetail' src={CartesRegion} alt="Cartes de la région" /> 
                </div>  
            </div>

            <div className='BtqDetail ColonBtqDetail'>
                <h3 className='H3BtqDetail'>Cartes de collection de la région
                <a className='LnkBtqDetail' href="https://www.yvesducourtioux.fr/" target="_blank" rel="noreferrer"> Yves Ducourtioux</a>
                </h3>
                <div className='Mag'>
                    <img className='ImagBtqDetail' src={CartesDucourtioux} alt="Gare de Troo" />
                </div>
            </div>  
                                  
            
            <Footer />
        </div>
    );
};

export default CartesDetail;